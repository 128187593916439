import { useState } from "react";
import { setRefillUser } from "../../../redux/actions/user.action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GreaterAccraSchools } from './utils/GreaterAccraSchools';
import { CentralRegionSchools } from './utils/CentralRegionSchools';
import { VoltaRegionSchools } from './utils/VoltaRegionSchools';
import { EasternRegionSchools } from './utils/EasternRegionSchools';

export const useRefillActions = (fromCart= false) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schoolDetails = [
    { id: 0,
      region: "Greater Accra",
      schools: GreaterAccraSchools,

    },

    {id: 1, region: "Ashanti Region",
      schools:[]
    },

    {id: 2, region: "Central Region",
      schools: CentralRegionSchools
    },

    {id: 3, region: "Eastern Region",
      schools: EasternRegionSchools
    },
    {id: 4, region: "Western Region",
      schools: []
    },

    {id: 5, region: "Volta Region",
      schools: VoltaRegionSchools
    },
  ]

  const courses = [
    "General Science",
    "General Arts",
    "Business",
    "Home Economics",
    "Visual Arts",
    "Agricultural Science"
  ]

  const formSet = [
    "Form One (1)",
    "Form Two (2)",
    "Form Three (3)",
  ]

  const regions = [
    {id: 0, name: "Greater Accra"},
    // {id: 1, name: "Ashanti"},
    {id: 2, name: "Central"},
    {id: 3, name: "Eastern"},
    // {id: 4, name: "Western"},
    {id: 5, name: "Volta"},
    // {id: 6, name: "Northern"},
    // {id: 7, name: "Upper East"},
    // {id: 8, name: "Upper West"},
    // {id: 9, name: "Brong Ahafo"},
    // {id: 10, name: "Western North"},
    // {id: 11, name: "Oti"},
    // {id: 12, name: "Savannah"},
    // {id: 13, name: "North East"},
    // {id: 14, name: "Bono"},
    // {id: 15, name: "Ahafo"},
  ]

  const refillUserDet = JSON.parse(localStorage.getItem("refillUser"));

  const [showRefillDialog, setShowRefillDialog] = useState(false);
  const [name, setName] = useState(refillUserDet?.name || "");
  const [phone, setPhone] = useState("");
  const [school, setSchool] = useState(0);
  const [region, setRegion] = useState(0);
  const [regionName, setRegionName] = useState("");
  const [house, setHouse] = useState("");
  const [form, setForm] = useState("");
  const [course, setCourse] = useState("");
  const [validPhone, setValidPhone] = useState(false);

  const refillUser = {
    name,
    school,
    region,
    regionName,
    house,
    form,
    course,
    phone
  };

  const formEmpty = !(name && house && form && course && phone);

  const saveRefillUser = () => {
    console.log({ useHook: refillUser });
    localStorage.setItem("refillUser", JSON.stringify({...refillUser, schoolName: schoolDetails[region].schools[school].name}));
    dispatch(setRefillUser({...refillUser, schoolName: schoolDetails[region].schools[school].name}));
    if (name && house && form && course && phone) {
      setShowRefillDialog(false);
      !fromCart && navigate("/refill_shop");
      // navigate("/refill_shop");
    }
  };

  const goToRefill = () => {
    setShowRefillDialog(true);
  };

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const onPhoneChange = (e) => {
    setPhone(e.target.value);
    setValidPhone(e.target.value.length > 9);
    console.log({refillUser: refillUser})
  }

  const onSchoolChange = (e) => {
    setSchool(e.target.value);
  }

  const onRegionChange = (e) => {
    setRegion(e.target.value);
    setRegionName(regions[e.target.value].name);
  }

  const onHouseChange = (e) => {
    setHouse(e.target.value);
  }

  const onFormChange = (e) => {
    setForm(e.target.value);
  }

  const onCourseChange = (e) => {
    setCourse(e.target.value);
  }

  return {
    state: {
      showRefillDialog,
      name,
      formEmpty,
      formSet,
      courses,
      regions,
      schoolDetails,
      region,
      school,
      house,
      form,
      course,
      phone,
    },
    actions: {
      setShowRefillDialog,
      onNameChange,
      onSchoolChange,
      onFormChange,
      onHouseChange,
      onCourseChange,
      onRegionChange,
      onPhoneChange,
      goToRefill,
      saveRefillUser
    }
  };
};
