import { Spin } from "antd";
import { useSelector } from "react-redux";
import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "../../css/slider.css";
import { isNotNilOrEmpty } from "ramda-adjunct";
import CategoryChip from "../CategoryChip";

export const CategoryChips = () => {
  const {
    categories,
    error: categoriesError,
    categoriesLoading
  } = useSelector((state) => state.allCategory);

  return (
    <>
      <div className="w-full md:flex hidden justify-between mt-[40px] mb-[20px]  session-header">
        <h2 className="text-app-orange ">
          Categories
          {categoriesLoading && <Spin />}
        </h2>
      </div>

      {/* Horizontal list of items */}
      <div className=" md:mt-[20px] mt-[10px] w-full scroll-m-8 scroll-auto snap-x overflow-x-auto whitespace-no-wrap">
        {categoriesLoading && <Spin size="25px" />}
        {isNotNilOrEmpty(categories) && (
          <>
            {categories?.map((cat) => {
              return (
                <span className="w-full text-center justify-around md:flex-row flex-col mr-[8px] ">
                  <CategoryChip cat={cat} className="mt-[4px]" />
                </span>
              );
            })}
          </>
        )}

        {categoriesError && (
          <p className="text-red-400 text-[10px]">An Error while loading the categories</p>
        )}
      </div>
    </>
  );
};
