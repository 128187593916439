import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../assets/css/slider.css";
import { Pagination, Navigation } from "swiper/modules";
import StandardProductCard from "../../../components/standardProductCard";

const ProductSlider = ({ data }) => {
  return (
    <Swiper
      modules={[Pagination, Navigation]}
      loop={false}
      navigation={true}
      breakpoints={{
        320: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30
        },
        1440: {
          slidesPerView: 5,
          spaceBetween: 30
        }
      }}
      pagination={{
        clickable: true
      }}
      className="productSlider mx-auto max-w-[360px] md:max-w-[1410px] sm:max-h-[200px]"
    >
      <>
        {data?.map((product) => {
          return (
            <SwiperSlide key={product._id}>
              <StandardProductCard product={product} />
            </SwiperSlide>
          );
        })}
      </>
    </Swiper>
  );
};

export default ProductSlider;
