import { message } from "antd";

export const SnackBar = ({ type, text }) => {
  const [messageApi, contextHolder] = message.useMessage();

  messageApi.open({
    type: type,
    content: text
  });

  return null; // We don't need to render anything in this component
};
