import { isNotNilOrEmpty } from "ramda-adjunct";
import { GiftDialog } from "../../screens/home/components/GiftDialog";
import { useSideActions } from "../../screens/home/hooks/useSideActions";

export const GiftAddress = () => {
  const altGiftUser = JSON.parse(localStorage.getItem("giftUser"));
  const giftShopping = isNotNilOrEmpty(altGiftUser);
  const { name, phone, address, city, landMark } = altGiftUser;
  const {
    state: { showGiftDialog, formEmpty },
    actions: { setShowGiftDialog }
  } = useSideActions();
  const imgUrl =
    "https://res.cloudinary.com/fitrip/image/upload/v1691307992/302-3022217_roger-berry-avatar-placeholder_klxaxk.png";

  return (
    <div className="bg-white rounded-2xl border border-slate-100 shadow pb-[15px] ">
      <GiftDialog
        show={showGiftDialog}
        setShow={setShowGiftDialog}
        formState={formEmpty}
        fromCart={true}
      />

      <div className="flex justify-between rounded-se-2xl rounded-t-2xl px-3 items-center bg-gray-200 bg-opacity-40 ">
        <h2 className="font-bold text-[12px] md:text-[15px] my-[10px] text-slate-700 p-2">
          DELIVERY ADDRESS
        </h2>
      </div>

      <ul role="list" className="divide-y divide-gray-100 ">
        <li className="flex justify-between gap-x-4 p-2 mx-4 mt-2 rounded-md bg-secondary-blue">
          <div className="flex min-w-0 gap-x-4">
            <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={imgUrl} alt="" />
            <div className="min-w-0 flex-auto">
              <div className="text-sm font-semibold leading-6 text-gray-900">{name}</div>
              <div className="mt-1 truncate text-xs leading-5 text-gray-500">{phone}</div>
            </div>
          </div>
        </li>
      </ul>
      <div className="ml-3 md:ml-15 pb-3 md:pb-10 mb-3 md:mb-10">
        <div className="grid grid-cols-2 gap-1 md:gap-2 text-[14px]">
          <p className="mt-1 md:mt-[5px]">{address}</p>
          <p className="mt-1 md:mt-[5px]">{city}</p>
          <p className="mt-1 md:mt-[5px]">{landMark}</p>
        </div>
      </div>

      <div className="flex justify-end items-center mr-4">
        <span
          className="px-2 py-1 text-sm border border-blue-500 text-blue-500 rounded-full cursor-pointer hover:bg-gray-300"
          onClick={() => {
            setShowGiftDialog(true);
          }}
        >
          Edit Address
        </span>
      </div>
    </div>
  );
};
