import React from "react";
import MainSlider from "./components/MainSlider";
import { SideActions } from "./components/SideActions";

const Hero = () => {
  return (
    <section className="mb-[30px] lg:pt-0 bg-primary-blue rounded-2xl bg-herobg">
      <div className="hero_container mx-auto">
        <div className="flex flex-col gap-y-[30px] xl:flex-row xl:gp-x-[30px]">
          <MainSlider />
          <SideActions />
        </div>
      </div>
    </section>
  );
};

export default Hero;
