import { Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetOrdersAction,
  ResetUpdateOrderAction,
} from "../redux/actions/order.action";
import { useDashboardOrders } from './hooks/useDashboardOrders';

const DashboardOrderFailed = ({ onClick }) => {
  const dispatch = useDispatch();
  const { order, loading, error } = useSelector((state) => state.updateOrder);
  const { orders, loading: ordersLoading } = useSelector((state) => state.allOrder);
  const { state:{columns}, actions:{renderOrders}} = useDashboardOrders()
  const failedOrders = orders?.filter((order) => order?.delivery_status === "failed");
  const data = renderOrders(failedOrders);

  useEffect(() => {
    if (order?._id) {
      dispatch(GetOrdersAction());
      dispatch(ResetUpdateOrderAction());
    }
  }, [order?._id]);

  return (
    <div className="w-full bg-white rounded-md p-[10px]">
      <div className="border-b  p-[8px]">Manage Orders</div>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default DashboardOrderFailed;
