import { HiOutlineShoppingCart } from "react-icons/hi";
import WideButton from "./wideButton";
import { useNavigate } from "react-router-dom";

export const EmptyCart = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full bg-white shadow-md rounded-2xl pt-[15px] pb-[15px]">
      <h2 className="font-bold ml-[15px] text-[12px] md:text-[15px] mb-[10px]">CART(0)</h2>
      <hr />
      <div className="flex justify-center items-center h-[300px] flex-col">
        <HiOutlineShoppingCart className="text-[100px]" />
        <h2 className="family-poppins mt-[30px] text-[25px] ">Oops!, your cart is empty</h2>
        <span>
          <WideButton
            style="bg-bright-blue mx-[10px] "
            text={"Go shopping "}
            onClick={() => navigate("/")}
          />
        </span>
      </div>
    </div>
  );
};
