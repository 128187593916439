import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetOrdersAction,
  ResetUpdateOrderAction,
} from "../redux/actions/order.action";
import { useNavigate } from "react-router-dom";
import { useDashboardOrders } from './hooks/useDashboardOrders';

const DashboardOrderPending = ({ onClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { order, loading, error } = useSelector((state) => state.updateOrder);
  const { orders, loading: ordersLoading } = useSelector((state) => state.allOrder);

  const pendingOrders = orders?.filter((order) => order?.delivery_status === "pending");

  console.log({pendingOrders: pendingOrders})
  const [reverseOrder, setReverseOrder] = useState([]);

  const { state:{columns}, actions:{renderOrders}} = useDashboardOrders()

  const data = renderOrders(pendingOrders);

  useEffect(() => {
    if (order?._id) {
      dispatch(GetOrdersAction());
      dispatch(ResetUpdateOrderAction());
    }
  }, [order?._id]);

  useEffect(() => {
    if (orders) {
      setReverseOrder([...pendingOrders]?.reverse());
    }
  }, [orders]);

  return (
    <div className="w-full bg-white rounded-md p-[10px]">
      <div className="border-b  p-[8px]">Manage Orders</div>

      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default DashboardOrderPending;
