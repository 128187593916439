import React from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";

const CategoryChip = (cat) => {
  const navigate = useNavigate();
  const { _id, image, name } = cat?.cat;

  return (
    <Chip
      onClick={() => {
        navigate(`/categories/${name}`, { state: { _id, name } });
      }}
      avatar={<Avatar alt="Category" src={image} />}
      label={name.substring(0, 18)}
      variant="outlined"
    />
  );
};

export default CategoryChip;
