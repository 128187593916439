import { Checkbox, Dropdown, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  GetOrdersAction,
  ResetUpdateOrderAction,
  updateOrderAction
} from "../redux/actions/order.action";
import { BiDotsVertical } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useDashboardOrders } from './hooks/useDashboardOrders';

const DashboardOrderAll = ({ onClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orders, loading: ordersLoading } = useSelector((state) => state.allOrder);
  const { order, loading, error } = useSelector((state) => state.updateOrder);
  const [reverseOrder, setReverseOrder] = useState([]);

  const { state:{columns}, actions:{renderOrders}} = useDashboardOrders()


  const data = renderOrders(reverseOrder);

  useEffect(() => {
    if (order?._id) {
      dispatch(GetOrdersAction());
      dispatch(ResetUpdateOrderAction());
    }
  }, [order?._id]);

  useEffect(() => {
    if (orders) {
      setReverseOrder([...orders]?.reverse());
    }
  }, [orders]);

  return (
    <div className="w-full bg-white rounded-md p-[10px]">
      <div className="border-b  p-[8px]">
        Manage Orders {(loading || ordersLoading) && <Spin />}{" "}
        {error && <p className="text-red-400">{error}</p>}
      </div>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default DashboardOrderAll;
