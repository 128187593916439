import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../css/slider.css";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import { Link, useNavigate } from "react-router-dom";

const sliderData = [
  {
    img: "https://res.cloudinary.com/fitrip/image/upload/v1689298367/slider11_eqvrez.png",
    pretittle: "Special Offer",
    titlePart1: "Save 20%",
    titlePart2: "On your",
    titlePart3: "first order",
    btnText: "Shop now!"
  },
  {
    img: "https://res.cloudinary.com/fitrip/image/upload/v1689300838/slider12_tkzhfp.png",
    pretittle: "We Deliver",
    titlePart1: "Delivery",
    titlePart2: "Within 24 hours",
    titlePart3: "",
    caption: "On all orders within Accra",
    btnText: "Shop now!"
  },
  {
    img: "https://res.cloudinary.com/fitrip/image/upload/v1689301335/slider13_juzg3v.png",
    pretittle: "Shop Now",
    titlePart1: "Get all ",
    titlePart2: "Essentials from",
    titlePart3: "Bevets",
    btnText: "Shop now!"
  }
];

const MainSlider = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full max-w-lg lg:max-w-[834px] mx-auto">
      <Swiper
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mainSlider h-full bg-primary xl:bg-mainSlider xl:bg-no-repeat max-w-lg lg:max-w-none rounded-[8px] overflow-hidden drop-shadow-2xl"
      >
        <>
          {sliderData.map((slide, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="flex flex-col lg:flex-row h-full p-[20px] md:p-[60px]">
                  <div className="w-full lg:flex-1">
                    <div className="uppercase mb-1 text-center lg:text-left">{slide.pretittle}</div>
                    <div className="text-3xl md:text-[46px] font-semibold  font-mont uppercase leading-none text-center lg:text-left mb-8 xl:mb-20">
                      {slide.titlePart1} <br />
                      {slide.titlePart2} <br />
                      {slide.titlePart3}
                    </div>
                    <button
                      onClick={() => {
                        console.log("balue");
                        navigate("/productslist");
                      }}
                      className="btn btn-accent mx-auto lg:mx-0 cursor-pointer "
                    >
                      Shop Now
                    </button>
                  </div>
                  <div className="flex-1">
                    <img
                      className="xl:absolute xl:-right-16 xl:bottom-8"
                      src={slide.img}
                      width={600}
                      alt=""
                    />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </>
      </Swiper>
    </div>
  );
};

export default MainSlider;
