import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Input } from "antd";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useRefillActions } from '../hooks/useRefillActions';
import { LuPackageOpen } from 'react-icons/lu';

export const RefillDialog = ({ show, setShow, formState, fromCart = false }) => {
  const {
    state: {
      name,
      phone,
      house,
      form,
      course,
      formSet,
      courses,
      schoolDetails,
      formEmpty,
      regions,
      region,
      school,
    },
    actions: {
      onNameChange,
      onPhoneChange,
      onSchoolChange,
      onRegionChange,
      onHouseChange,
      onFormChange,
      onCourseChange,
      saveRefillUser

    }
  } = useRefillActions(fromCart);

  const refillUser = JSON.parse(localStorage.getItem("refillUser"));

  const saveRefillUserLocal = () => {
    setShow(false);
    saveRefillUser();
  };

  console.log({ ibiza:  schoolDetails[region]})
  console.log({ ibizaRegion:  region})

  return (
    <Dialog open={show} onClose={() => setShow(false)}>
      <DialogTitle className="bg-background ">
        <span><LuPackageOpen color="orange" className="inline mr-2"  /></span>
        <span>Refill my box</span>
      </DialogTitle>
      <DialogContent className="mt-2">
        {fromCart ? (
          <DialogContentText className={formEmpty ? "text-red-900" : ""}>
            Please update details of <span className="text-app-orange ">{refillUser.name}</span>
          </DialogContentText>
        ) : (
          <DialogContentText className={formEmpty ? "text-red-900" : ""}>
            Please add details of the student you are shopping for:
          </DialogContentText>
        )}

        <div className="md:mx-6">
          {!fromCart && (
            <div className="flex flex-col">
              <div className="checkout-field mt-4">
                <label>Name</label>
                <Input name="name" type="text" value={name} onChange={onNameChange} />
              </div>
            </div>
          )}
        </div>
        <div className="md:mx-6">
          <div className="flex flex-col">
            <div className="checkout-field mt-4">
              <FormControl fullWidth  size="small">
                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={region}
                  label="School"
                  onChange={onRegionChange}
                  dense
                >
                  {
                     regions.map((region)=>{
                      return <MenuItem value={region.id}>{region.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="md:mx-6">
          <div className="flex flex-col">
            <div className="checkout-field mt-4">
              <FormControl fullWidth  size="small">
                <InputLabel id="demo-simple-select-label">School</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={school}
                  label="School"
                  onChange={onSchoolChange}
                  dense
                >
                  {
                    schoolDetails.map((regionData) =>
                        regionData.id === region &&
                        regionData.schools.map((schoolInfo) => (
                          <MenuItem key={schoolInfo.id} value={schoolInfo.id}>
                            {schoolInfo.name}
                          </MenuItem>
                        ))
                      )
                  }
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="md:mx-6">
          <div className="flex flex-col">
            <div className="checkout-field mt-4">
              <FormControl fullWidth  size="small">
                <InputLabel id="demo-simple-select-label">House</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={house}
                  label="House"
                  onChange={onHouseChange}
                  dense
                >
                  {
                    // schoolDetails[0]?.schools[school].house?.map((house)=>{
                    //   return <MenuItem value={house?.name}>{house?.name}</MenuItem>
                    // })
                    // schoolDetails[region].schools[school].house.map(house => (
                    //     <MenuItem key={house.name} value={house.name}>
                    //       {house.name}
                    //     </MenuItem>
                    //   ))

                    schoolDetails.map((regionData) =>
                        regionData.id === region &&
                        regionData.schools.map((schoolInfo) =>
                          schoolInfo.id === school &&
                          schoolInfo.house.map((house) => (
                            <MenuItem key={house.name} value={house.name}>
                              {house.name}
                            </MenuItem>
                          ))
                        )
                      )

                  }
                </Select>
              </FormControl>
            </div>
          </div>
        </div><div className="md:mx-6">
          <div className="flex flex-col">
            <div className="checkout-field mt-4">
              <FormControl fullWidth  size="small">
                <InputLabel id="demo-simple-select-label">Form</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={form}
                  label="Form"
                  onChange={onFormChange}
                  dense
                >
                  {
                    formSet.map((form) => {
                      return (<MenuItem value={form}>{form}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="md:mx-6">
          <div className="flex flex-col">
            <div className="checkout-field mt-4">
              <FormControl fullWidth  size="small">
                <InputLabel id="demo-simple-select-label">Course</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={course}
                  label="House"
                  onChange={onCourseChange}
                  dense
                >
                  {
                    courses.map((course) => {
                      return (<MenuItem value={course}>{course}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="md:mx-6">
          <div className="flex flex-col">
            <div className="checkout-field mt-4">
              <label>Phone No.</label>
              <Input name="phone" type="text" value={phone} onChange={onPhoneChange} />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="flex justify-between">
        <Button onClick={() => setShow(false)}>Cancel</Button>
        <Button variant="contained" onClick={saveRefillUserLocal} disabled={formEmpty}>
          {fromCart ? "Update" : "Start Shopping"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
