import * as React from "react";
import { useUser } from "../../../hooks/useUser";
import { isNotNilOrEmpty } from "ramda-adjunct";

export const GiftTag = () => {
  const {
    state: { giftUser }
  } = useUser();

  const altGiftUser = JSON.parse(localStorage.getItem("giftUser"));

  const person = isNotNilOrEmpty(giftUser) ? giftUser : altGiftUser;

  const imgUrl =
    "https://res.cloudinary.com/fitrip/image/upload/v1691307992/302-3022217_roger-berry-avatar-placeholder_klxaxk.png";
  return isNotNilOrEmpty(person) ? (
    <div className="sticky-top-0">
      <ul role="list" className="divide-y divide-gray-100  ">
        <li
          key={person.email}
          className="flex justify-between gap-x-4 p-2 rounded-md bg-secondary-blue"
        >
          <div className="flex min-w-0 gap-x-4">
            <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={imgUrl} alt="" />
            <div className="min-w-0 flex-auto">
              <div className="text-sm font-semibold leading-6 text-gray-900">{person.name}</div>
              <div className="mt-1 truncate text-xs leading-5 text-gray-500">{person.phone}</div>
            </div>
          </div>
          <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
            <span className="text-sm leading-6 text-gray-900">{person.email}</span>
            <span className="mt-1 text-xs leading-5 text-gray-500">{person.address}</span>
          </div>
        </li>
      </ul>
    </div>
  ) : (
    <></>
  );
};
