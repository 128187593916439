import { useState } from "react";
import { test } from "ramda";
import { setGiftUser } from "../../../redux/actions/user.action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useSideActions = (fromCart) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const giftUserDet = JSON.parse(localStorage.getItem("giftUser"));

  const [showGiftDialog, setShowGiftDialog] = useState(false);
  const [showRefillDialog, setShowRefillDialog] = useState(false);
  const [name, setName] = useState(giftUserDet?.name || "");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [landMark, setLandMark] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [validAddress, setValidAddress] = useState(false);
  const [discloseIdentity, setDiscloseIdentity] = useState(false);
  const [validName, setValidName] = useState(false);
  const [validCity, setValidCity] = useState(false);
  const [validLandMark, setValidLandMark] = useState(false);

  const giftUser = {
    name,
    phone,
    address,
    city,
    landMark
  };

  const formEmpty = !(name && phone && address && city && landMark);

  const saveGiftUser = () => {
    console.log({ useHook: giftUser });
    localStorage.setItem("giftUser", JSON.stringify(giftUser));
    dispatch(setGiftUser(giftUser));
    if (name && phone && address && city && landMark) {
      setShowGiftDialog(false);
      !fromCart && navigate("/gift_shop");
      console.log({ useHook33: showGiftDialog });
    }
  };

  const goToSelf = () => {
    navigate("/productslist");
  };

  const goToGift = () => {
    setShowGiftDialog(true);
  };

  const goToRefill = () => {
    setShowRefillDialog(true);
  };

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const onPhoneChange = (e) => {
    setPhone(e.target.value);
    const validPhone = test(/^(\+?234|0)[\d]{10}$/, e.target.value);
    setValidPhone(validPhone);
  };

  const onAddressChange = (e) => {
    setAddress(e.target.value);
    const validAddress = test(/[\w\d\s]+/, e.target.value);
    setValidAddress(validAddress);
  };

  const onCityChange = (e) => {
    setCity(e.target.value);
    const validCity = test(/[\w\d\s]+/, e.target.value);
    setValidCity(validCity);
  };

  const onLandMarkChange = (e) => {
    setLandMark(e.target.value);
    const validLandMark = test(/[\w\d\s]+/, e.target.value);
    setValidLandMark(validLandMark);
  };

  const onDiscloseIdentity = (e) => {
    console.log(e.target.checked);
    setDiscloseIdentity(e.target.checked);
  };

  return {
    state: {
      showGiftDialog,
      showRefillDialog,
      phone,
      city,
      address,
      validPhone,
      validAddress,
      name,
      formEmpty,
      discloseIdentity
    },
    actions: {
      setShowGiftDialog,
      setShowRefillDialog,
      onNameChange,
      onPhoneChange,
      onAddressChange,
      onCityChange,
      onLandMarkChange,
      onDiscloseIdentity,
      goToSelf,
      goToGift,
      goToRefill,
      saveGiftUser
    }
  };
};
