const wesleyGirlsHouses = [
  {name:"Waldron House"},
  {name:"Bellamy House"},
  {name:"Ellis House"},
  {name:"Wringley House"},
  {name:"Wardbrew House"},
  {name:"Compton House"},
  {name:"Abban House"},
  {name:"Garnett House"},
  {name:"Acheampong House"},
  {name:"Thompson Djokoto House"},
]

const mfansimanGirlsHouses = [
  {name:"Chinery House"},
  {name:"Butler House"},
  {name:"Croffie House"},
  {name:"Engmann House"},
  {name:"Yeboah House"},
  {name:"Scotton House"},
]

const mfansipimHouses = [
  {name:"Balmer-Acquah House"},
  {name:"Pickard-Parker House"},
  {name:"Lockhart-Schweitzer House"},
  {name:"Sarbah-Picot House"},
  {name:"Freeman-Aggrey House"},
  {name:"Bartels-Sneath House"},
  {name:"Abrquah-Monney House"},
  {name:"Brandful-Dontwi House"},
];


const adisadelHouses = [
  {name:"Aglionby House"},
  {name:"Canterbury House"},
  {name:"Ebiradze House"},
  {name:"Elliott House"},
  {name:"Hamlyn House"},
  {name:"Jubilee House"},
  {name:"Knight House"},
  {name:"Lemaire House"},
  {name:"Quaque House"},
  {name:"Thomas Jonah House"},
];

const auguscoHouses = [
  {name:"St. Peter's House"},
  {name:"Father Glenn House"},
  {name:"St. Stephen's House"},
  {name:"St. Patrick's House"},
  {name:"St. Theresa's House"},
  {name:"St. Luke's House"},
  {name:"St. George's House"},
  {name:"Father Kelly House"},
  {name:"St. Joseph's House"},
  {name:"St. John's House"},
  {name:"Nduom House"},
];

const ghanaNationalHouses = [
  {name:"Elizerbeth Wardbury House"},
  {name:"Faustina Daniels House"},
  {name:"Twidan House"},
  {name:"Charlotte Bartplangne House"},
  {name:"Kwesi Plangne House"},
  {name:"Swidu McCarthy House"},
  {name:"J.J. Mensah Kane House"},
  {name:"Wardbury House"},
];

const aggreyHouses = [
  {name:"Pinanko House"},
  {name:"Segbefia House"},
  {name:"Enchil House"},
  {name:"Anne Lucille House"},
  {name:"Watson House"},
  {name:"Casely Hayford House"},
  {name:"Katherine Aikins House"},
  {name:"New House"},
];

export const CentralRegionSchools = [
  {
    id: 0,
    name:"Wesley Girls High School (WEYGEYHEY)",
    house: wesleyGirlsHouses,
    location:"Cape Coast"
  },

  {id: 1,
    name:"Mfansiman Girls High(SITE)",
    house:mfansimanGirlsHouses,
    location:"Cape Coast"
  },

  {
    id: 2,
    name:"Holy Child School(HOLYCO)",
    house:mfansimanGirlsHouses,
    location:"Cape Coast"
  },
  {
    id: 3,
    name:"Mfantsipim School(BOTWE)",
    house:mfansipimHouses,
    location:"Cape Coast"
  },
  {
    id: 4,
    name:"Adisadel College (ADISCO)",
    house:adisadelHouses,
    location:"Cape Coast"
  },
  {
    id: 5,
    name:"St. Augustine's College (AUGUSCO)",
    house:auguscoHouses,
    location:"Cape Coast"
  },
  {
    id: 6,
    name:"Ghana National College (NATIONAL)",
    house:ghanaNationalHouses,
    location:"Cape Coast"
  },
  {
    id: 7,
    name:"Aggrey Memorial Zion Senior High (AGGREY)",
    house:aggreyHouses,
    location:"Cape Coast"
  }



]