import { Spin } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../css/slider.css";
import { Pagination, Navigation } from "swiper/modules";
import CategoryCard from "./CategoryCard";
import { isNotNilOrEmpty } from "ramda-adjunct";
import "animate.css"

export const CategoryList = () => {
  const {
    categories,
    error: categoriesError,
    categoriesLoading
  } = useSelector((state) => state.allCategory);

  return (
    <>
      <div className="w-full md:flex hidden justify-between mt-[130px] mb-[20px]  session-header">
        <h2 className=" ">
          Shop <span className="text-app-orange session-header">by Categories</span>
          {categoriesLoading && <Spin />}
        </h2>

        <Link className="text-app-orange session-header" to="/categories">
          See all
        </Link>
      </div>

      {/* Horizontal list of items */}
      <div className="animate__bounceIn md:mt-[0px] mt-[10px] w-full scroll-m-8 scroll-auto snap-x overflow-x-auto whitespace-no-wrap">
        {categoriesLoading && <Spin size="25px" />}
        <Swiper
          modules={[Pagination, Navigation]}
          loop={false}
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 0
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 0
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 0
            },
            1440: {
              slidesPerView: 6,
              spaceBetween: 0
            }
          }}
          pagination={{
            clickable: true
          }}
          className="productSlider mx-auto max-w-[360px] md:max-w-[1410px]"
        >
          {isNotNilOrEmpty(categories) && (
            <>
              {categories?.map((cat) => {
                return (
                  <SwiperSlide key={Math.random()}>
                    <CategoryCard cat={cat} />
                  </SwiperSlide>
                );
              })}
            </>
          )}
        </Swiper>

        {categoriesError && (
          <p className="text-red-400 text-[10px]">An Error while loading the categories</p>
        )}
      </div>
    </>
  );
};
