import React, { useState, useEffect } from "react";
import Header from "../components/header";
import ScreenWithPadding from "../components/ScreenWithPadding";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrderByIdAction } from "../redux/actions/order.action";
import { Spin } from "antd";
import { MainOrderDetails } from './mainOrderDetails';
import moment from 'moment';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import LoadingScreen from '../components/elements/Loading';

const OrderByIdScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { order: orderData, loading, error } = useSelector((state) => state.orderById);

  useEffect(() => {
    dispatch(getOrderByIdAction(id));
  }, []);

  const totalPrice = isNotNilOrEmpty(orderData) && orderData?.items.reduce((total, item) => total + (item.product.price * item.qty), 0);
  const imgUrl = "https://res.cloudinary.com/fitrip/image/upload/v1691307992/302-3022217_roger-berry-avatar-placeholder_klxaxk.png";

  return (
    <div className="">
      <Header />
      {
        (loading && isNotNilOrEmpty(orderData)) ? (
          <LoadingScreen />
        ):(
          <ScreenWithPadding>
            <div className="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
              <div className="flex justify-start item-start space-y-2 flex-col">
                {/*<h1 className="text-3xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800">Order #{orderData?._id.substring(0,12)}</h1>*/}
                <h1 className="text-3xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800">Order details</h1>
                <p className="text-base dark:text-gray-300 font-medium leading-6 text-gray-600">{ moment(orderData?.createdAt).format('Do MMMM YYYY [at] h:mm A')}</p>
              </div>
              <div className="mt-10 flex flex-col xl:flex-row justify-center items-stretch w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
                <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
                  <div className="flex flex-col justify-start items-start dark:bg-gray-800 bg-gray-50 rounded-xl px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
                    <p className="text-lg md:text-xl dark:text-white font-semibold leading-6 xl:leading-5 text-gray-800">Customer’s Cart</p>
                    {/*Product start*/}

                    {
                      orderData?.items?.map((prod) => {
                        return (
                          <div className="flex flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full">
                            <div className="pb-4 md:pb-8 w-full md:w-40">
                              <img className="w-[120px] h-[140px]" src={prod?.product?.images && prod?.product?.images[0]} alt="product" />
                            </div>
                            <div className="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full pb-3 space-y-1 md:space-y-0">
                              <div className="w-full flex flex-col justify-start items-start space-y-8">
                                <h3 className="text-xl dark:text-white xl:text-xl font-semibold leading-6 text-gray-800">{prod?.product?.name}</h3>
                                <div className="flex justify-start items-start flex-col space-y-2">
                                  <p className="text-sm dark:text-white leading-none text-gray-800"><span className="dark:text-gray-600 text-gray-400 font-bold">Description: </span> {prod?.product?.description.substring(0, 20)}</p>
                                  <p className="text-sm dark:text-white leading-none text-gray-800"><span className="dark:text-gray-600 text-gray-400 font-bold">Size: </span> Normal</p>
                                </div>
                              </div>
                              <div className="flex justify-between space-x-8 items-start w-full">
                                <p className="text-base dark:text-white xl:text-lg leading-6 text-orange-400">GH₵ {prod?.product?.price}</p>
                                <p className="text-base dark:text-white xl:text-lg leading-6 text-gray-800">{prod?.qty}</p>
                                <p className="text-base dark:text-white xl:text-lg font-semibold leading-6 text-green-800">GH₵{+prod?.product?.price * +prod?.qty}</p>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }

                    {/*Product end*/}
                  </div>
                  <div className="flex justify-center flex-col md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
                    <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 dark:bg-gray-800 space-y-6 rounded-xl border-blue-500">
                      <h3 className="text-xl dark:text-white font-semibold leading-5 text-gray-800">Summary</h3>
                      <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                        <div className="flex justify-between w-full">
                          <p className="text-base dark:text-white leading-4 text-gray-800">Subtotal</p>
                          <p className="text-base dark:text-gray-300 leading-4 text-gray-600">GH₵{totalPrice}.00</p>
                        </div>
                        {/*<div className="flex justify-between items-center w-full">*/}
                        {/*  <p className="text-base dark:text-white leading-4 text-gray-800">Discount <span className="bg-gray-200 p-1 text-xs font-medium dark:bg-white dark:text-gray-800 leading-3 text-gray-800">STUDENT</span></p>*/}
                        {/*  <p className="text-base dark:text-gray-300 leading-4 text-gray-600">-GH₵28.00 (50%)</p>*/}
                        {/*</div>*/}
                        <div className="flex justify-between items-center w-full">
                          <p className="text-base dark:text-white leading-4 text-gray-800">Shipping</p>
                          <p className="text-base dark:text-gray-300 leading-4 text-gray-600">GH₵0.00</p>
                        </div>
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p className="text-base dark:text-white font-semibold leading-4 text-gray-800">Total</p>
                        <p className="text-base dark:text-gray-300 font-semibold leading-4 text-gray-600">GH₵{totalPrice}.00</p>
                      </div>
                    </div>
                    <div className="flex flex-col justify-center px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 dark:bg-gray-800 rounded-xl space-y-6">
                      <h3 className="text-xl dark:text-white font-semibold leading-5 text-gray-800">Shipping</h3>
                      <div className="flex justify-between items-start w-full">
                        <div className="flex justify-center items-center space-x-4">
                          <div className="w-8 h-8">
                            <img className="w-full h-full" alt="logo" src="https://i.ibb.co/L8KSdNQ/image-3.png" />
                          </div>
                          <div className="flex flex-col justify-start items-center">
                            <p className="text-lg leading-6 dark:text-white font-semibold text-gray-800">Bevets Delivery<br /><span className="font-normal">Delivery with 24 Hours</span></p>
                          </div>
                        </div>
                        <p className="text-lg font-semibold leading-6 dark:text-white text-gray-800">GH₵0.00</p>
                      </div>
                      <div className="w-full flex justify-center items-center">
                        <button className="hover:bg-black dark:bg-white dark:text-gray-800 dark:hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-5 w-96 md:w-full bg-gray-800 text-base font-medium leading-4 text-white">Contact Bevets</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 dark:bg-gray-800 rounded-xl w-full xl:w-96 flex justify-between items-center md:items-start px-4 py-6 md:p-6 xl:p-8 flex-col">
                  <h3 className="text-xl dark:text-white font-semibold leading-5 text-gray-800">Customer</h3>
                  <div className="flex flex-col md:flex-row xl:flex-col justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0">
                    <div className="flex flex-col justify-start items-start flex-shrink-0">
                      <div className="flex justify-center w-full md:justify-start items-center space-x-4 py-8 border-b border-gray-200">
                        <img src={imgUrl} alt="avatar" className="w-[60px]"/>
                        <div className="flex justify-start items-start flex-col space-y-2">
                          <p className="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">{orderData?.user?.firstname +' '+orderData?.user?.firstname}</p>
                          <p className="text-sm dark:text-gray-300 leading-5 text-gray-600">{orderData?.user?.mobile}</p>
                        </div>
                      </div>

                      <div className="flex justify-center text-gray-800 dark:text-white md:justify-start items-center space-x-4 py-4 border-b border-gray-200 w-full">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M3 7L12 13L21 7" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <p className="cursor-pointer text-sm leading-5 ">{orderData?.user?.email}</p>
                      </div>
                    </div>
                    <div className="flex justify-between xl:h-full items-stretch w-full flex-col mt-6 md:mt-0">
                      <div className="flex justify-center md:justify-start xl:flex-col flex-col md:space-x-6 lg:space-x-8 xl:space-x-0 space-y-4 xl:space-y-12 md:space-y-0 md:flex-row items-center md:items-start">
                        <div className="flex justify-center md:justify-start items-center md:items-start flex-col space-y-4 xl:mt-8">
                          <p className="text-base dark:text-white font-semibold leading-4 text-center md:text-left text-gray-800">Shipping Address</p>
                          <p className="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">{orderData?.address?.address + ' '+ orderData?.address?.city}</p>
                          <p className="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">{orderData?.address?.state}</p>
                          <p className="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">+233 {orderData?.address?.zipCode}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ScreenWithPadding>
        )
      }
    </div>
  );
};

export default OrderByIdScreen;
