import { Input, Spin, message } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isNilOrEmpty, isNotNilOrEmpty } from "ramda-adjunct";
import { GetAddressByIdAction } from "../redux/actions/address.action.js";
import {
  OrderDetailsAction,
  CreateOrderAction,
  ResetOrderDetailsAction
} from "../redux/actions/order.action";
import { ResetCartAction } from "../redux/actions/product.action.js";
import PayButton from "../utils/PayButton";
import "../styles/checkout.css";
import { showErrorToast } from "../utils/AlertDialogs";
import { SnackBar } from "../utils/SnackBar";
import { GiftAddress } from "./elements/GiftAddress";
import { RefillAddress } from './elements/RefillAddress';

const SpecUser = ()=>{

  const giftUserDet = JSON.parse(localStorage.getItem("giftUser"));

  return (
    isNotNilOrEmpty(giftUserDet) ? <GiftAddress /> : <RefillAddress />
  )
}

function PaymentMethodSession({ flip, setFlip }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { address, delivery_method, items, payment_method, total_price, user } = useSelector(
    (state) => state.orderDetails
  );
  const { cartItems } = useSelector((state) => state.cart);
  const { address: addressFromId, loading, error } = useSelector((state) => state.addressById);
  const {
    loading: createOrderLoading,
    error: createOrderError,
    order: createdOrder
  } = useSelector((state) => state.createOrder);

  const giftUserDet = JSON.parse(localStorage.getItem("giftUser"));
  const refillUserDet = JSON.parse(localStorage.getItem("refillUser"));
  const giftShopping = isNotNilOrEmpty(giftUserDet);
  const refillShopping = isNotNilOrEmpty(refillUserDet);

  const orderType = (() => {
    return giftShopping ? "gift" : (refillShopping ? "refill" : "self");
  })();

  const paymentMethod = "Payment before delivery.";
  const [messageApi, contextHolder] = message.useMessage();

  const deliveryFee = refillShopping ? 70 : 25;

  const amount =
    (cartItems
      ?.reduce(
        (accumulator, currectValue) =>
          accumulator + currectValue?.product?.price * currectValue?.qty,
        0
      )+ deliveryFee)
      ?.toFixed(2) * 100;

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const hasDetails = email || name || phone;

  const resetForm = () => {
    setEmail("");
    setName("");
    setPhone("");
  };

  const orderItems = () =>
    cartItems.map((item) => {
      if (item?.product?._id) {
        return {
          product: item?.product?._id,
          qty: item?.qty
        };
      }
    });

  const ConfirmOrder = () => {
    if (!paymentMethod) {
      return messageApi.open({
        type: "warning",
        content: "Select a payment method"
      });
    }

    dispatch(
      CreateOrderAction({
        type: orderType,
        giftDetails: giftUserDet,
        refillDetails: refillUserDet,
        address,
        delivery_method,
        items: orderItems(),
        payment_method,
        total_price,
        user
      })
    );

    navigate("/orders");

    dispatch(ResetCartAction());
  };

  useEffect(() => {
    if (!address && !giftShopping && !refillShopping) {
      messageApi.open({
        type: "warning",
        content: "Please select an address"
      });
      return setFlip(!flip);
    }
    (isNilOrEmpty(giftUserDet) || isNotNilOrEmpty(refillUserDet)) && dispatch(GetAddressByIdAction(address));
  }, [address, dispatch]);

  useEffect(() => {
    dispatch(
      OrderDetailsAction({
        payment_method: paymentMethod
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (createdOrder?._id) {
      messageApi.open({
        type: "success",
        content: "Order has been created"
      });

      navigate("/orders");
      dispatch(ResetOrderDetailsAction());
    }
  }, [createdOrder]);

  const showSnack = (type, text) => {
    SnackBar(type, text);
  };

  return (
    <div className="flex md:flex-row flex-col justify-between w-full font-mont">
      {contextHolder}
      <div className="md:w-8/12 w-full flex flex-col  md:mr-[10px] mr-0">
        {(giftShopping || refillShopping) ? (
          <SpecUser />
        ) : (
          <div className="bg-white rounded-2xl border border-slate-100 shadow pb-[15px] ">
            <div className="flex justify-between rounded-se-2xl rounded-t-2xl px-3 items-center bg-gray-200 bg-opacity-40 ">
              <h2 className="font-bold text-[12px] md:text-[15px] my-[10px] text-slate-700 p-2">
                SELECTED DELIVERY ADDRESS DETAILS {loading && <Spin />} {error && <p>{error}</p>}
              </h2>
            </div>
            <hr />
            <div className="ml-[15px]">
              <p className="font-bold mb-[15px] text-[15px] mt-[10px]">{addressFromId?.address}</p>
              <p className=" mt-[5px] text-[11px]"> {addressFromId?.city}</p>

              <p className=" mt-[5px] text-[11px]">{addressFromId?.state}</p>
              <p className=" mt-[5px] text-[11px]">{addressFromId?.zipCode}</p>
            </div>
          </div>
        )}

        <div className="bg-white rounded-2xl border border-slate-100 shadow pb-[15px] mt-8">
          <div className="flex justify-between rounded-se-2xl rounded-t-2xl px-3 items-center bg-gray-200 bg-opacity-40 ">
            <h2 className="font-bold text-[12px] md:text-[15px] my-[10px] text-slate-700 p-2">
              PAYMENT DETAILS
            </h2>
          </div>
          <hr />
          <div className=" mb-[10px] flex flex-col">
            <div className="flex flex-col px-5">
              <div className="checkout-field mt-4">
                <label>Name</label>
                <Input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="checkout-field mt-2">
                <label>Email</label>
                <Input
                  type="text"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {/* <div className="checkout-field mt-2"> */}
              {/*	<label>Phone</label> */}
              {/*	<Input */}
              {/*		type="text" */}
              {/*		id="phone" */}
              {/*		value={phone} */}
              {/*		onChange={(e) => setPhone(e.target.value)} */}
              {/*	/> */}
              {/* </div> */}
            </div>

            <div className="ml-[20px] mt-[20px]">
              <p className="text-text-gray text-sm ">
                1. Your security, our priority. You keep control of every transaction and are
                protected against fraud and stealth.
                <br />
                <br />
                2. Before you proceed, please make sure you have enough money in your mobile money
                wallet or bank account linked to your card.
              </p>
            </div>

            <hr className="my-[15px]" />

            <div className="flex pb-[30px] pt-[15px] px-[30px] w-full flex-col ">
              <div className="flex w-full justify-between ">
                <p>Subtotal</p>
                <p>
                  GHC{" "}
                  {cartItems
                    ?.reduce(
                      (accumulator, currectValue) =>
                        accumulator + currectValue?.product?.price * currectValue?.qty,
                      0
                    )
                    ?.toLocaleString()}
                </p>
              </div>
              <div className="flex w-full justify-between my-[5px]">
                <p>Delivery Fee</p>
                <p className="font-semibold">GHC {deliveryFee?.toLocaleString()}</p>
              </div>
            </div>

            <hr className="my-[10px] mt-[-20px]" />
            <div className="flex px-[30px] w-full flex-col ">
              <div className="flex w-full justify-between">
                <p className="font-bold">Total</p>
                <p className="text-app-orange font-bold">
                  GHC{" "}
                  {(cartItems
                    ?.reduce(
                      (accumulator, currectValue) =>
                        accumulator + currectValue?.product?.price * currectValue?.qty,
                      0
                    ) + deliveryFee)
                    ?.toLocaleString()}
                </p>
              </div>
            </div>

            <div className="mx-[50px]">
              {hasDetails ? (
                <PayButton amount={amount} email={email} onPaySuccess={ConfirmOrder} />
              ) : (
                <button className="paystack-button bg-bright-blue " onClick={showErrorToast}>
                  Pay Now
                </button>
              )}
            </div>
            {createOrderLoading && <Spin size="large" />}
          </div>
        </div>
      </div>
      <div className="md:w-4/12 w-full bg-white rounded-2xl shadow-md p-[10px] md:mt-0 mt-[20px] flex flex-col ">
        {" "}
        <h2 className="font-bold ml-[15px] text-[12px] md:text-[15px] my-[5px] ">
          YOUR ORDER ({" "}
          {cartItems?.reduce((accumulator, currentValue) => accumulator + currentValue?.qty, 0)}{" "}
          items)
        </h2>
        <hr />
        {cartItems?.map((product) => (
          <div className="flex mt-[15px] px-[20px] mb-[20px]  items-start ">
            <img
              alt=""
              src={product?.product?.images && product?.product?.images[0]}
              className="h-[100px] w-[100px]"
            />

            <div className="ml-[20px]">
              <p>{product?.product?.name}</p>
              <p className="text-app-orange mt-[13px]">
                GHC {product?.item?.price?.toLocaleString()}
              </p>
              <p>
                Quantity:
                {product?.qty}
              </p>
            </div>
          </div>
        ))}
        <hr />
        <div className="flex justify-between mt-[20px]">
          <p>Subtotal</p>
          <p>
            GHC{" "}
            {cartItems
              ?.reduce(
                (accumulator, currectValue) =>
                  accumulator + currectValue?.product?.price * currectValue?.qty,
                0
              )
              ?.toLocaleString()}
          </p>
        </div>
        <div className="flex justify-between mt-[10px]">
          <p>Delivery Fee</p>
          <p className="font-semibold">GHC {deliveryFee?.toLocaleString()}</p>
        </div>
        {/* <div className="flex justify-between mt-[10px] mb-[5px]">
					<p>Discount</p>
					<p className="text-bright-blue">GHC 0.00</p>
				</div> */}
        <hr />
        <div className="flex justify-between  mt-[10px]">
          <p className="font-bold">Total</p>
          <p className="text-app-orange font-bold">
            GHC{" "}
            {(cartItems
              ?.reduce(
                (accumulator, currectValue) =>
                  accumulator + currectValue?.product?.price * currectValue?.qty,
                0
              ) + deliveryFee)
              ?.toLocaleString()}
          </p>
        </div>
        <button
          className="self-center w-[40%] py-[5px] text-white rounded-lg mt-[20px] mb-[10px] bg-bright-blue"
          onClick={() => {
            navigate("/cart");
          }}
        >
          Modify Cart
        </button>
      </div>
    </div>
  );
}

export default PaymentMethodSession;
