import React, {useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import "../lib/specialPrint.css"

export const SpecialOrder = ({order}) => {
  const navigate = useNavigate();
  const printDiv = useRef()
  const isGift = order?.type === "gift";
  const isRefill = order?.type === "refill";
  const giftUser = order?.giftDetails || {};
  const refillUser = order?.refillDetails || {};

  const handlePrint = () => {
    const content = printDiv.current;

    if (content) {
      const printWindow = window.open('', '', 'width=600,height=600');
      printWindow.document.open();
      printWindow.document.write(`
          <html lang="">
          <head>
          <title>Print</title>
          <style>
        body {
         .printDiv {
        border: 1px solid #e3e3e3;
        padding: 30px;
        border-radius: 10px;
       width: 65%;
     }
       .printDiv p{
        font-weight: bold;
     }
     .printDiv span {
        margin-left: 20px;
        font-weight: lighter;
    }
    .clickBut{
    display: none;
    }
  }
       </style>
          </head>
          <body>${content?.innerHTML}</body>
          </html>`);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
  };

  return (
    <div ref={printDiv}>
      {isGift ? (
      <div className="rounded-lg bg-background border p-[15px] w-full printDiv">
        <div className="flex w-full flex-row justify-between items-center mt-[10px] text-[12px]">
          <p className={`font-bold text-[12px] bg-green-500 px-[5px] hover:cursor-pointer py-[2px] rounded-lg text-white`}> {isGift? 'SHOP AS GIFT': 'REFILL MY BOX'}</p>
          <p
            className="text-[12px] border px-[5px] py-[3px] hover:cursor-pointer bg-white clickBut"
            onClick={handlePrint}

          >
            Print
          </p>
        </div>
        <div className="px-[10px] w-full pb-[1px]">
          <div className="text-[12px] mt-[-10px]">
            <p className="font-bold mt-[5px]">Receiver's Name: <span className="font-light ml-4"> {giftUser.name} </span></p>
            <p className="font-bold mt-[5px]">Phone : <span className="font-light ml-4"> {giftUser.phone} </span></p>
            <p className="font-bold mt-[5px]">Address : <span className="font-light ml-4"> {giftUser.address} </span></p>
            <p className="font-bold mt-[5px]">City : <span className="font-light ml-4"> {giftUser.city} </span></p>
            <p className="font-bold mt-[5px]">Landmark : <span className="font-light ml-4"> {giftUser.landMark} </span></p>
          </div>
        </div>
      </div>
    ):(
        <div className="rounded-lg bg-background border p-[15px] w-full printDiv ">
          <div className="flex w-full flex-row justify-between items-center mt-[10px] text-[12px]">
            <p className={`font-bold text-[12px]  bg-orange-400 px-[5px] hover:cursor-pointer py-[2px] rounded-lg text-white`}> {isGift? 'SHOP AS GIFT': 'REFILL MY BOX'}</p>
            <p
              className="text-[12px] border rounded shadow px-[5px] py-[3px] hover:cursor-pointer bg-white clickBut"
              onClick={handlePrint}
            >
              Print
            </p>
          </div>
          <div className="px-[10px] w-full pb-[5px]">
            <div className="text-[12px] mt-[-10px]">
              <p className="font-bold mt-[25px]">Student Name: <span className="font-light ml-4"> {refillUser?.name} </span></p>
              <p className="font-bold mt-[25px]">Phone No. : <span className="font-light ml-4"> {refillUser?.phone} </span></p>
              <p className="font-bold mt-[25px]">School Name:<span className="font-light ml-4"> {refillUser?.schoolName} </span></p>
              <p className="font-bold mt-[25px]">Region: <span className="font-light ml-4"> {refillUser?.regionName} </span></p>
              <p className="font-bold mt-[25px]">Course: <span className="font-light ml-4"> {refillUser?.course} </span></p>
              <p className="font-bold mt-[25px]">House: <span className="font-light ml-4"> {refillUser?.house} </span></p>
              <p className="font-bold mt-[25px]">Form: <span className="font-light ml-4"> {refillUser?.form} </span></p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}