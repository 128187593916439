
const stPetersHouses =[
  {name: 'Augustine House'},
  {name: 'Porres House'},
  {name: 'Claver House'},
  {name: 'Luanga House'},
  {name: 'Foucauld House'},
  {name: 'Kizito House'},
]

const stRosesHouses = [
  {name: "House One(1)"},
  {name: "House Two(2)"},
  {name: "House Three(3)"},
  {name: "House Four(4)"},
  {name: "House Five(5)"},
  {name: "House Six(6)"},
  {name: "House Seven(7)"},
  {name: "House Eight(8)"},
]
const oforiPaninHouses = [
  {name: "HOUSE ONE(1)"},
  {name: "HOUSE TWO(2)"},
  {name: "HOUSE THREE(3)"},
  {name: "HOUSE FOUR(4)"},
  {name: "HOUSE FIVE(5)"},
  {name: "HOUSE SIX(6)"},
  {name: "HOUSE SEVEN(7)"},
  {name: "HOUSE EIGHT(8)"}

]

const popeJohnHouses = [
  {name: "AMOS HOUSE"},
  {name: "ELSBERND HOUSE"},
  {name: "MPOSO HOUSE"},
  {name: "BOWERS HOUSE"},
  {name: "POPE JOHN HOUSE"},
  {name: "KALENGA HOUSE"},
  {name: "BOAKYE HOUSE"},
]

const kroboGirlsHouses = [
  {name: "AGNES AKOTO HOUSE"},
  {name: "ST. DAVIDS HOUSE"},
  {name: "LAMONT HOUSE"},
  {name: "CAIRNS HOUSE"},
  {name: "DENCAL HOUSE"},
  {name: "NEUK HOUSE"},
]

const koforiduaHouses = [
  {name: "MACBRUCE HOUSE"},
  {name: "OKWABI HOUSE"},
  {name: "HOUSE ONE(1)"},
  {name: "HOUSE TWO(2)"},
  {name: "HOUSE THREE(3)"},
  {name: "HOUSE FOUR(4)"},
  {name: "HOUSE FIVE(5)"},
]

const ghanaHouses = []

export const EasternRegionSchools =[
  {
    id: 0,
    name:"St. Peter's Senior High School (PERSCO)",
    house: stPetersHouses,
    location:"Nkwatia"
  },
  {
    id: 1,
    name:"St. Roses Senior High School (ROSEC)",
    house: stRosesHouses,
    location:"Akwatia"
  },
  {
    id: 2,
    name:"Pope John Senior High School (POJOSS)",
    house: popeJohnHouses,
    location:"Koforidua"
  },
  {
    id: 3,
    name: "Krobo Girls Senior High School (KROBO)",
    house: kroboGirlsHouses,
    location:"Odumase-Krobo"

  },
  {
    id: 4,
    name:"Koforidua Senior High/Technical School (KSTS)",
    house: koforiduaHouses,
    location:"Koforidua"
  },
  {
    id: 5,
    name:"Ofori Panin Senior High School (OPASS)",
    house: oforiPaninHouses,
    location:"Kukurantumi"
  },
  {
    id: 6,
    name:"Ghana Senior High School (GHANASS)",
    house: ghanaHouses,
    location:"Koforidua"
  }


]