import React from "react";
import { Spin } from "antd";

const WideButton = ({ onClick, text, style, loading = false }) => {
  return (
    <button
      className={`mt-[10px] mb-[10px] w-full md:py-[8px] py-[5px] text-[13px] font-semibold border-none outline-none  ${style} family-inter text-white rounded-lg`}
      onClick={onClick}
    >
      {text}
      {loading && <Spin className="ml-1" />}
    </button>
  );
};

export default WideButton;
