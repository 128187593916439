import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import localForage from "localforage";

const persistConfig = {
  key: "root",
  storage: localForage,
  whitelist: ["userLogin"] // Add the state slice you want to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk, logger];

const store = configureStore({
  reducer: persistedReducer, // Use the persisted reducer
  middleware
});

export const persistor = persistStore(store); // Create the persistor

export default store;