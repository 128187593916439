
const mawuliHouses = [
  {name: "Aggrey House"},
  {name: "Lincoln House"},
  {name: "Wilberforce House"},
  {name: "Trost House"},
  {name: "Aku House"},
  {name: "Nightingale House"},
  {name: "Slessor House"},
  {name: "Priscilla House"},
  {name: "Snitker House"},
  {name: "Solace House"},

]
const olaGirlsHouses = []
const bishopHermanHouses = [
  {name: "ST. AUGUSTINE'S HOUSE"},
  {name: "ST. CYPRAIN'S HOUSE"},
  {name: "ST. MAURICE'S HOUSE"},
  {name: "ST. VICTOR'S HOUSE"},
  {name: "BISHOP LODONU'S HOUSE"},
]

export const VoltaRegionSchools = [
  {
    id: 0,
    name: "MAWULI SCHOOL",
    house: mawuliHouses,
    location: "Ho"
  },
  {
    id: 1,
    name: "OLA Girls Senior High School",
    house: olaGirlsHouses,
    location: "Ho"
  },
  {
    id: 2,
    name: "Bishop Herman College",
    house: bishopHermanHouses,
    location: "Kpando"
  }

]