import React, { useEffect } from "react";
import Header from "../components/header";
import ScreenWithPadding from "../components/ScreenWithPadding";
import { useDispatch, useSelector } from "react-redux";
import { OrderByUserAction } from "../redux/actions/order.action";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { MainOrder } from './mainOrder';

const MyOrderScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orders, loading, error } = useSelector((state) => state.orderByUser);

  useEffect(() => {
    dispatch(OrderByUserAction());
  }, []);

  return (
    <div className="bg-background">
      <Header />
      <ScreenWithPadding>
        <MainOrder orderSet={orders} />
      </ScreenWithPadding>
    </div>
  );
};

export default MyOrderScreen;
