import { Checkbox, Dropdown, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetOrdersAction,
  ResetUpdateOrderAction,
  updateOrderAction
} from "../../redux/actions/order.action";
// } from "../redux/actions/order.action";
import moment from "moment";
import { BiDotsVertical } from "react-icons/bi";
import { useNavigate } from "react-router-dom";


export const useDashboardOrders = ()=>{

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    {
      title: "Order No",
      dataIndex: "orderNo"
    },
    {
      title: "Date",
      dataIndex: "createdAt"
    },
    {
      title: "Customer",
      dataIndex: "user"
    },
    {
      title: "Price",
      dataIndex: "total_price"
    },
    {
      title: "Order Type",
      dataIndex: "type"
    },
    {
      title: "Details",
      dataIndex: "details"
    },
    {
      title: "Order status",
      dataIndex: "status"
    },
    {
      title: "Actions",
      dataIndex: "actions"
    }
  ];

   const items = (id) => {
    return [
      {
        label: (
          <p
            onClick={() => {
              dispatch(updateOrderAction(id, { delivery_status: "success" }));
            }}
          >
            Mark as Delivered
          </p>
        ),
        key: "0"
      },
      {
        label: (
          <p
            onClick={() => {
              dispatch(updateOrderAction(id, { delivery_status: "pending" }));
            }}
          >
            Mark as Pending
          </p>
        ),
        key: "1"
      },
      {
        label: (
          <p
            onClick={() => {
              dispatch(updateOrderAction(id, { delivery_status: "failure" }));
            }}
          >
            Mark as Failed
          </p>
        ),
        key: "2"
      },
      {
        label: (
          <p
            onClick={() => {
              navigate(`/admin/orders/${id}`);
            }}
          >
            Change Tracking Details
          </p>
        ),
        key: "3"
      }
    ];
  };

  const renderOrders = (orders) => {
    return orders?.map(order => {
      return {
        orderNo: (
          <p onClick={() => { navigate(`/admin/orders/${order?._id}`); }} className="hover:cursor-pointer hover:text-blue-400">
            {order?._id?.slice(0, 5)}
          </p>
        ),
        createdAt: (
          <p onClick={() => { navigate(`/admin/orders/${order?._id}`); }} className="hover:cursor-pointer hover:text-blue-400">
            {moment(order?.createdAt).format("YYYY-MM-DD")}
          </p>
        ),
        user: (
          <p onClick={() => { navigate(`/admin/orders/${order?._id}`); }} className="hover:cursor-pointer hover:text-blue-400">
            {`${order?.user?.firstname} ${order?.user?.lastname}`}
          </p>
        ),
        total_price: (
          <p onClick={() => { navigate(`/admin/orders/${order?._id}`); }} className="hover:cursor-pointer hover:text-blue-400">
            {`GHc ${order?.total_price}`}
          </p>
        ),
        type: (
          <p onClick={() => { navigate(`/admin/orders/${order?._id}`); }} className={`hover:cursor-pointer hover:text-blue-400 ${order?.type === "self" ? "" : "text-green-500 font-bold"}`}>
            {order?.type.toUpperCase()}
          </p>
        ),
        details: (
          <p onClick={() => { navigate(`/admin/orders/${order?._id}`); }} className="border border-blue-500 text-blue-500 rounded-full py-1 px-3 text-center hover:cursor-pointer hover:text-white hover:bg-blue-500">
            View Details
          </p>
        ),
        status: (
          <span className={`${order?.delivery_status === "pending" ? "text-orange-400" : ""} ${order?.delivery_status === "success" ? "text-green-500" : ""} ${order?.delivery_status === "failed" ? "text-red-500" : ""}`}>
          {order?.delivery_status}
        </span>
        ),
        actions: (
          <button className="bg-bright-blue text-white rounded-lg py-[5px] px-[5px]">
            <Dropdown menu={{items: items(order?._id)}} trigger={["click"]}>
              <BiDotsVertical />
            </Dropdown>
          </button>
        )
      }
    })
  }


  return {
    state: {
      columns,
      items,
    },
    actions: {
      renderOrders,

    }
  }
}

