import React from "react";
import { PaystackButton } from "react-paystack";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const PayButton = ({ amount, email, onPaySuccess }) => {
  const navigate = useNavigate();
  const publicKey = process.env.REACT_APP_PS_PUBLIC_LIVE_KEY;
  const [reference, setReference] = React.useState("");

  const handlePaystackSuccessAction = (reference) => {
    Swal.fire({
      icon: "success",
      title: "Payment Successful",
      text: "Your payment has been received!",
      confirmButtonColor: "green"
    });
    localStorage.removeItem("giftUser");
    onPaySuccess();
    setTimeout(() => {
      navigate("/orders");
    }, 3000);
  };

  const handlePaymentCancel = () => {
    Swal.fire({
      icon: "info",
      title: "Payment Cancelled",
      text: "Your payment has been cancelled!",
      confirmButtonColor: "#408BD5"
    });
  };

  const componentProps = {
    email,
    amount,
    publicKey,
    currency: "GHS",
    text: "Pay Now",
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: () => handlePaymentCancel()
  };

  return <PaystackButton className="paystack-button bg-bright-blue" {...componentProps} />;
};

export default PayButton;
