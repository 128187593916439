
const presecHouses = [
  {name:"Kwansa House"},
  {name:"Clerk House"},
  {name:"Engmann House"},
  {name:"Akro House"},
  {name:"RIIS House"},
  {name:"Labone House"},
  {name:"Ako-Adjei House"},
  {name:"Owusu Parry"},
  {name:"House 9"},
  {name:"House 10"},
]

const accraAcademyHouses = [
  {name:"Alema House"},
  {name:"Awuletey House"},
  {name:"Ellen House"},
  {name:"Nana Wereko Ampem House"},
  {name:"Peter Ala Adjetey House"},
  {name:"Nana Akuoku Sarpong House"},
  {name:"Halm Addo House"},
]

const achimotaHouses = [
  {name:"Aggrey House"},
  {name:"Guggisberg House"},
  {name:"Gyamfi House"},
  {name:"Cadbury House"},
  {name:"Lugard House"},
  {name:"Livingstone House"},
  {name:"Fraser House"},
  {name:"Kwapong House"},
  {name:"Kingsley House"},
  {name:"McCarthy House"},
  {name:"Slessor House"},
  {name:"Clark House"},
  {name:"S.O.A House"},
  {name:"Baeta House"},
  {name:"Stopford House"},
  {name:"Atta Mills House"},
  {name:"Aryee House"},
]
const stMarysHouses = [
  {name:"Butler House"},
  {name:"Kirk House"},
  {name:"Latham House"},
  {name:"Temple West House"},

]
const laboneHouses = [
  {name:"House One"},
  {name:"House Two"},
  {name:"House Three"},
  {name:"House Four"},
  {name:"House Five"},
  {name:"House Six"},
]
const aburiGirlsHouses = [
  {name:"Irene Anderson House"},
  {name:"Edinburgh House"},
  {name:"Royal Park House"},
  {name:"Barradale House"},
  {name:"Kilsyth House"},
  {name:"Aberdeen House"},
  {name:"Chapel House"},
  {name:"Sylvia Asempa House"},

]
const okuapemanHouses = [
  {name:"Opoku Acheampong House"},
  {name:"Akuffo House"},
  {name:"Kwadade House"},
  {name:"Dokua Acheampong House"},
  {name:"Dokua Akuffo House"},
  {name:"Dokua Kwadade House"},
]
const accraGirlsHouses = [
]


export const GreaterAccraSchools = [

  { id: 0,
    name:"Presbyterian Boys Senior High School(PRESEC)",
    house:presecHouses,
    location:"Legon"
  },
  {id: 1,name:"Accra Academy Senior High School(ACRACA)",
    house:accraAcademyHouses,
    location:"Kaneshie"
  },
  {
    id: 2,
    name:"Achimota Senior High School",
    house:achimotaHouses,
    location:"Achimota"

  },
  {
    id: 3,
    name:"Labone Senior High School",
    house:laboneHouses,
    location:"Labone"
  },
  {
    id: 4,
    name: "Aburi Girls Senior High School(Abugiss)",
    house: aburiGirlsHouses,
    location:"Aburi"
  },
  {
    id: 5,
    name:"Okuapeman Senior High School(Okuass)",
    house:okuapemanHouses,
    location:"Akropong"
  },
  {
     id: 6,
     name: "St. Mary's Senior High School",
      house: stMarysHouses,
      location:"Accra"
  },
  {
    id: 7,
    name: "Accra Girls Senior High School",
    house: accraGirlsHouses,
    location:"Accra"
  }


]