import React, { useEffect, useState } from "react";
import { FaUserAlt, FaWarehouse } from "react-icons/fa";
import { AiFillInfoCircle } from "react-icons/ai";
import { HiShoppingCart } from "react-icons/hi";
import { BsFillCollectionFill } from "react-icons/bs";
import { MdFavorite } from "react-icons/md";
import { Dropdown, Badge, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUserAction } from "../redux/actions/user.action";
import { RegexSearchAction, ResetRegexAction } from "../redux/actions/product.action";
import { isEmpty, reject } from "ramda";

const SearchMobile = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { products } = useSelector((state) => state.regexSearch);

  const filteredCartItems = reject(isEmpty, cartItems);

  const cartCount = () => {
    return filteredCartItems?.length || 0;
  };

  const dropDownItem = () => {
    return products?.map((product) => {
      return {
        key: product?._id,
        label: (
          <div
            key={product?._id}
            className="flex flex-row w-full"
            onClick={() => {
              navigate(`/product/${product?._id}`, {
                state: {
                  categoryName: product?.category?.name,
                  categoryId: product?.category?._id
                }
              });
            }}
          >
            <img alt="" src={product?.images && product?.images[0]} className="h-[50px] w-[50px]" />{" "}
            <div className="ml-[10px]">
              <p>{product?.name}</p>
              <p className="text-[12px] my-[-2px]">{product?.category?.name}</p>
              <p className=" text-[10px]">{product?.description?.slice(0, 20)}</p>
            </div>
          </div>
        )
      };
    });
  };

  const items = [
    {
      label: (
        <>
          {userInfo?._id ? (
            <div
              className="items-center w-full md:flex hover:cursor-pointer "
              onClick={() => {
                navigate("/account");
              }}
            >
              <span className="mr-2 text-black">
                <FaUserAlt />
              </span>
              View Profile
            </div>
          ) : (
            <></>
          )}
        </>
      ),
      key: "0"
    },

    {
      label: (
        <>
          {userInfo?._id ? (
            <div
              className="items-center w-full md:flex hover:cursor-pointer "
              onClick={() => {
                navigate("/");
                dispatch(logoutUserAction());
              }}
            >
              <span className="mr-2 text-black">
                <FaUserAlt />
              </span>
              Logout
            </div>
          ) : (
            <div
              className="items-center w-full md:flex hover:cursor-pointer "
              onClick={() => {
                navigate("/login");
              }}
            >
              <span className="mr-2 text-black">
                <FaUserAlt />
              </span>
              {userInfo?._id ? "My Account" : "Login"}
            </div>
          )}
        </>
      ),
      key: "1"
    },
    {
      label: (
        <div
          className="items-center w-full  md:flex hover:cursor-pointer"
          onClick={() => {
            navigate("/orders");
          }}
        >
          <span className="mr-2 text-black">
            <BsFillCollectionFill />
          </span>
          Orders
        </div>
      ),
      key: "2"
    },
    {
      label: (
        <div className="items-center w-full  justify-start md:flex hover:cursor-pointer mb-2">
          <span className="mr-2 text-black">
            <MdFavorite />
          </span>
          Saved Items
        </div>
      ),
      key: "3"
    }
  ];

  useEffect(() => {
    if (search === "") {
      return dispatch(ResetRegexAction());
    }

    if (search !== "") {
      dispatch(RegexSearchAction(search));
    }
  }, [search]);

  return (
      <div className="w-full md:hidden flex bg-app-orange h-[60px] mt-[30px] justify-center items-center">
      <Dropdown
        menu={{
          items: dropDownItem()
        }}
      >
        <div className="items-center justify-between md:hidden w-11/12 bg-white md:flex rounded-3xl">
          <input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            className="md:h-[30px] h-[30px] w-full rounded-2xl px-3 border-none outline-none"
            placeholder="Search products and categories"
          />
        </div>
      </Dropdown>

      <div className="md:flex hidden  justify-between md:w-[25%] w-[70%]  ml-[10%] p-2">
        <Dropdown menu={{ items }} trigger={["click"]} overlayClassName={{ hover: "blue" }}>
          <div className="flex flex-row items-center h3 hover:cursor-pointer">
            <span className="mr-2 text-black ">
              <FaUserAlt />
            </span>
            Profile
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default SearchMobile;
