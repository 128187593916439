import { useSelector } from "react-redux";
import { userLoginSlice } from "../redux/reducers/user.slice";

export const useUser = () => {
  const { loginRequest, loginSuccess, logOut, loginFailed } = userLoginSlice.actions;

  const { userInfo } = useSelector((state) => state.userLogin);
  const { giftUser } = useSelector((state) => state.userLogin);
  const { refillUser } = useSelector((state) => state.userLogin);

  const loggedInUser = JSON.parse(localStorage.getItem("userInfo"));

  return {
    state: {
      userInfo: userInfo || loggedInUser,
      giftUser,
      refillUser,
      loginSuccess,
      loginRequest,
      loginFailed
    },
    actions: {
      logOut
    }
  };
};
