import { useSideActions } from "../hooks/useSideActions";
import { GiftDialog } from "./GiftDialog";
import { RefillDialog } from './RefillDialog';
import { useRefillActions } from '../hooks/useRefillActions';
import 'animate.css';

export const SideActions = () => {

  const {
    state: { showGiftDialog, formEmpty },
    actions: { goToSelf, goToGift, setShowGiftDialog }
  } = useSideActions();

  const {
    state: { formEmpty:refillEmpty, showRefillDialog, region, school },
    actions: { goToRefill, setShowRefillDialog }
  } = useRefillActions();

  const sideActions = [
    {
      title: "Shop for self",
      caption: "",
      imgUrl: "https://res.cloudinary.com/fitrip/image/upload/v1689294949/shop_self_gi9uv0.png",
      buttonText: "Shop Now",
      clickAction: goToSelf
    },
    {
      title: "Shop as gift",
      caption: "",
      imgUrl: "https://res.cloudinary.com/fitrip/image/upload/v1689289800/giftimage_xtvevu.png",
      buttonText: "Shop Now",
      clickAction: goToGift
    },
    {
      title: "Refill My Box",
      caption: "",
      imgUrl: "https://res.cloudinary.com/fitrip/image/upload/v1692319886/chopbox1_2_z3aqpi.png",
      buttonText: "Shop Now",
      clickAction: goToRefill
    }
  ];

  return (
    <div className="flex flex-col gap-y-[30px] w-full max-w-lg mx-auto h-[500px]">
      <GiftDialog show={showGiftDialog} setShow={setShowGiftDialog} formState={formEmpty} region={region} school={school} />
      <RefillDialog show={showRefillDialog} setShow={setShowRefillDialog} formState={refillEmpty} />
      {sideActions.map((action, index) => {
        return (
          // <div className="grad flex-1 h-[200px] rounded-[8px] overflow-hidden relative p-6 bg-slate-200 m-[20px] bg-opacity-25">
           <div className="animate__animated animate__slideInRight grad flex-1 h-[200px] rounded-[8px] relative overflow-hidden bg-slate-200 bg-opacity-25 p-4 m-[10px]">
            <div className="flex flex-col md:max-w-[204px] h-full justify-center">
              <div className="text-[20px] uppercase font-medium mb-4 font-mont">
                {action.title}
              </div>
              <button className="btn btn-accent mr-auto lg:mx-0" onClick={action.clickAction}>
                {action.buttonText}
              </button>
            </div>
            <img
              className={"absolute z-20 -top-2 -right-4 md:w-[35%] w-[55%] md:block w-[200px] "+ (index === 2 ? "mt-[35px]": "")}
              src={action.imgUrl}
              alt="Promo 1"
            />
          </div>
        );
      })}
    </div>
  );
};
