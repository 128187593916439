import React, { useState } from "react";
import { Input, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLoginAction } from "../redux/actions/user.action";
import { useEffect } from "react";
import useProduct from "../hooks/useProduct";
import { isNotNilOrEmpty } from "ramda-adjunct";

const LoginScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo, loading, error } = useSelector((state) => state.userLogin);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {
    state: { cartItems }
  } = useProduct();

  const login = (e) => {
    e.preventDefault();
    dispatch(userLoginAction({ email, password }));
  };

  useEffect(() => {
    if (userInfo?.role === "admin") {
      return navigate("/dashboard", {
        state: {
          page: ""
        }
      });
    }

    if (userInfo?._id) {
      isNotNilOrEmpty(cartItems) ? navigate("/cart") : navigate("/");
    }
  }, [userInfo]);

  return (
    <div className="flex pt-[50px] flex-col bg-background h-screen items-center w-full">
      <img alt="bevets" src={"/assets/logo.png"} className="w-[120px] md:mt-0 " />

      <h1 className="mt-[20px] font-extrabold text-[25px] font-mont text-gray-800">Welcome!</h1>
      <p className="mt-[20px] text-[15px] font-poppins text-gray-800">
        Log in to your Bevets account
      </p>

      <form className="mt-[30px] md:w-[30%] sm:w-[40%] w-[80%] authForm" onSubmit={login}>
        {/*<Input*/}
        {/*	value={email}*/}
        {/*	onChange={(e) => setEmail(e.target.value)}*/}
        {/*	placeholder="email@email.com"*/}
        {/*	className=" rounded-xl mb-[23px] px-3 bg-primary-blue md:py-[5px] py-[3px] border-black outline-black"*/}
        {/*/>*/}

        <div className="checkout-field">
          <label>Email</label>
          <Input
            placeholder="email@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="bg-white border-black"
          />
        </div>
        <br />
        <div className="checkout-field">
          <label>Password</label>
          <Input.Password
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {error && (
          <p className="text-red-400 my-[5px] text-center">
            {"Sorry! an error occoured while trying to login."}
          </p>
        )}

        <button
          className="md:mt-[15px] mt-[6px] w-full md:py-[5px] py-[5px] text-[10px] md:text-[15px] font-semibold border-none outline-none bg-app-orange family-inter rounded-2xl text-white hover:shadow-md"
          type="submit"
          onClick={login}
        >
          Login
          {loading && <Spin />}
        </button>
      </form>

      <p className="mt-[20px] text-[12px] md:text-[15px] family-poppins">
        Don't have an account?{" "}
        <button className="text-app-orange" onClick={() => navigate("/signup")}>
          Sign up
        </button>
      </p>

      <p className="text-app-orange mt-[20px] text-[12px] md:text-[15px] family-poppins cursor-pointer">
        Forgot your password
      </p>

      <p className="text-[10px] md:hidden flex font-light w-[60%] text-clip text-center bottom-[40px] fixed">
        For further support, you may visit the Help Center or contact our customer service team.
      </p>
    </div>
  );
};

export default LoginScreen;
