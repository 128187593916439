import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSideActions } from "../hooks/useSideActions";
import { Input } from "antd";
import { useEffect } from "react";
import { AiOutlineGift } from 'react-icons/ai';

export const GiftDialog = ({ show, setShow, formState, fromCart = false }) => {
  const {
    state: {
      name,
      phone,
      address,
      city,
      landMark,
      validPhone,
      validAddress,
      validEmail,
      validName,
      formEmpty,
      discloseIdentity
    },
    actions: {
      saveGiftUser,
      onNameChange,
      onPhoneChange,
      onAddressChange,
      onCityChange,
      onLandMarkChange,
      onDiscloseIdentity
    }
  } = useSideActions(fromCart);

  const giftUser = JSON.parse(localStorage.getItem("giftUser"));

  const saveGiftUserLocal = () => {
    setShow(false);
    saveGiftUser();
  };

  return (
    <Dialog open={show} onClose={() => setShow(false)}>
      <DialogTitle className="bg-background ">
        <AiOutlineGift color="orange" className="inline mr-2"  />
        Shop as gift</DialogTitle>
      <DialogContent className="mt-2">
        {fromCart ? (
          <DialogContentText className={formEmpty ? "text-red-900" : ""}>
            Please update details of <span className="text-app-orange ">{giftUser.name}</span>
          </DialogContentText>
        ) : (
          <DialogContentText className={formEmpty ? "text-red-900" : ""}>
            Please add details of the person you are shopping for:
          </DialogContentText>
        )}

        <div className="md:mx-6">
          {!fromCart && (
            <div className="flex flex-col">
              <div className="checkout-field mt-2">
                <label>Name</label>
                <Input name="name" type="text" value={name} onChange={onNameChange} />
              </div>
            </div>
          )}
        </div>
        <div className="md:mx-6">
          <div className="flex flex-col">
            <div className="checkout-field mt-2">
              <label>Phone No.</label>
              <Input name="phone" type="text" value={phone} onChange={onPhoneChange} />
            </div>
          </div>
        </div>
        <div className="md:mx-6">
          <div className="flex flex-col">
            <div className="checkout-field mt-2">
              <label>Address / Location</label>
              <Input name="address" type="text" value={address} onChange={onAddressChange} />
            </div>
          </div>
        </div>
        <div className="md:mx-6">
          <div className="flex flex-col">
            <div className="checkout-field mt-2">
              <label>City</label>
              <Input name="city" type="text" value={city} onChange={onCityChange} />
            </div>
          </div>
        </div>
        <div className="md:mx-6">
          <div className="flex flex-col">
            <div className="checkout-field mt-2">
              <label>Land Mark</label>
              <Input name="landMark" type="text" value={landMark} onChange={onLandMarkChange} />
            </div>
          </div>
        </div>

        {/*<AddressInput />*/}

        <div className="relative flex gap-x-3 mt-4 md:ml-6">
          <div className="flex h-6 items-center">
            <input
              id="candidates"
              value={discloseIdentity}
              onChange={onDiscloseIdentity}
              name="candidates"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
          </div>
          <div className="text-sm leading-6">
            <label htmlFor="candidates" className="font-medium text-gray-900">
              Disclose my identity
            </label>
            <p className="text-gray-500">Let {name} know i sent the gift.</p>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="flex justify-between">
        <Button onClick={() => setShow(false)}>Cancel</Button>
        <Button variant="contained" onClick={saveGiftUserLocal} disabled={formEmpty}>
          {fromCart ? "Update" : "Start Shopping"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
